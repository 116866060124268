import { useRef } from 'react';
import 'tinymce/tinymce';
import 'tinymce/models/dom/model';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
//import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/image';
import 'tinymce/plugins/table';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';
import { Editor, } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import { iHtmlEditorConfig } from '../../interfaces/iMailboxConfig';
import React from 'react';
import { GetCachedEditorConfig } from '../../Logic/TenantConfigCache';
import { handleInsideClick } from '../../hooks/useOutsideClick';

type EditorProps = {
    onChange: Function,
    initialValue: string,
    inlineImage: Function,
    inlineA: any,
    mailbox: number,
    attachmentHandler: Function
}

function HtmlEditor(props: EditorProps) {

    const editorRef = useRef<TinyMCEEditor | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [HtmlEditorConfig, setHtmlEditorConfig] = React.useState<iHtmlEditorConfig>({} as iHtmlEditorConfig);

    React.useEffect(() => {
        (async () => {
            const mailboxConfig = await GetCachedEditorConfig(props.mailbox);
            if (Object.keys(mailboxConfig).length > 0) {
                let htmlConfig: iHtmlEditorConfig = {} as iHtmlEditorConfig;

                htmlConfig.Toolbar = 'undo redo | fontfamily  styles  |  ';
                if (mailboxConfig.boldEnabled) {
                    htmlConfig.Toolbar += ' bold '
                }
                if (mailboxConfig.italicEnabled) {
                    htmlConfig.Toolbar += ' italic '
                }
                if (mailboxConfig.underlineEnabled) {
                    htmlConfig.Toolbar += ' underline  '
                }

                if (mailboxConfig.availableTextColors.length > 0) {
                    htmlConfig.Toolbar += ' forecolor  '
                    htmlConfig.TextColors = [];
                }

                if (mailboxConfig.availableBackColors.length > 0) {
                    htmlConfig.Toolbar += ' backcolor  '
                    htmlConfig.BackColors = [];
                }
                htmlConfig.Toolbar += ' | ';
                if (mailboxConfig.linkEnabled) {
                    htmlConfig.Toolbar += ' link ';
                }
                if (mailboxConfig.imageEnabled) {
                    htmlConfig.Toolbar += ' image ';
                }
                if (mailboxConfig.imageEnabled || mailboxConfig.linkEnabled) {
                    htmlConfig.Toolbar += ' | ';
                }
                if (mailboxConfig.alignmentEnabled) {
                    htmlConfig.Toolbar += ' alignleft aligncenter alignright alignjustify |  '
                }
                if (mailboxConfig.bulletListEnabled) {
                    htmlConfig.Toolbar += ' bullist ';
                }
                if (mailboxConfig.numberListEnabled) {
                    htmlConfig.Toolbar += ' numlist ';
                }
                htmlConfig.Toolbar += ' checklist outdent indent | removeformat | ';
                if (mailboxConfig.tableEnabled) {
                    htmlConfig.Toolbar += ' table ';
                }

                mailboxConfig.availableBackColors.map(
                    (item, index) => {
                        htmlConfig.BackColors.push(item.hexCode);
                        htmlConfig.BackColors.push(item.label)
                    }
                )
                mailboxConfig.availableTextColors.map(
                    (item, index) => {
                        htmlConfig.TextColors.push(item.hexCode);
                        htmlConfig.TextColors.push(item.label)
                    }
                )
                htmlConfig.Fonts = [];
                mailboxConfig.availableFonts.map(
                    (item, index) => {
                        htmlConfig.Fonts.push(item);

                    }
                )
                htmlConfig.DefaultFont = mailboxConfig.defaultFont;
                setHtmlEditorConfig(htmlConfig);
            }
        })();
    }, [props.mailbox]);

    const handleDrop = async (params: any) => {
        params.preventDefault();
        params.stopImmediatePropagation();
        params.stopPropagation();
        processFiles(params.dataTransfer.files);
    }
    const processFiles = async (files: FileList) => {
        if (files.length == 1) {
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
            if (!validImageTypes.includes(files[0]['type'])) {
                props.attachmentHandler(files);
            }
            else {
                const dataUrl = await URL.createObjectURL(files[0]);
                const fileName = (files[0].name);
                let img = new Image();
                img.onload = async function (e: any) {
                    let a = e;
                    let imgWidth = e.target!.width > 800 ? 800 : e.target!.width;
                    var id = Math.random().toString(16).slice(2);
                    let blob = await fetch(dataUrl).then(r => r.blob());
                    editorRef.current?.insertContent("<img src='" + dataUrl + "' title='" + id + "' alt='" + fileName + "' width='" + imgWidth + "'/>");
                    window.setTimeout(() => { props.inlineImage(blob, dataUrl, fileName, id); }, 500);
                };
                img.src = dataUrl;

            }
        } else if (files.length > 1) {
            props.attachmentHandler(files);
        }
    }
    const placeCursor = (ed: TinyMCEEditor) => {
        window.setTimeout(() => {
            let editor = ed;
            var node = editor.dom.select('div#' + "templateBody");
            let cur = -1;
            if (node.length > 0) {
                for (var x = 0; x < node[0].childNodes.length; x++) {
                    cur = node[0].childNodes[x].textContent!.indexOf('[cursor]');
                    if (cur >= 0) {
                        node[0].childNodes[x].textContent = node[0].childNodes[x].textContent!.replaceAll("[cursor]", "‎ ");
                        editor.selection.setCursorLocation(node[0].childNodes[x]!.firstChild!, cur);
                        editor.undoManager.clear(); // prevents the user from undoing the cursor placeholder replacement
                        props.onChange(editor.dom.doc.documentElement.outerHTML);
                        break;
                    }
                }
                editor.dom.doc.children[0].children[1].children[0].setAttribute('contenteditable', 'false');
            }
            if (cur == -1 && node.length > 0) {
                editor.selection.setCursorLocation(node[0].firstChild!, 0);
            }
            let bookmar = editor.selection.getBookmark();
            editor.selection.moveToBookmark(bookmar);
            editor.selection.scrollIntoView();
            props.onChange(editor.dom.doc.documentElement.outerHTML);
        }, 15);

    };
    return (
        <>

            {
                Object.keys(HtmlEditorConfig).length > 0 &&
                <Editor
                    onEditorChange={(content: any, editor) => {
                        props.onChange(editor.dom.doc.documentElement.outerHTML);
                    }}
                    onLoadContent={(evt, ed) => {
                        placeCursor(ed);
                    }}
                    onDrop={handleDrop} // this is to handle if an image is drag  and dropped onto the control
                    onInit={(evt, ed) => {
                        editorRef.current = ed;
                        ed.focus();
                        ed.getBody().setAttribute('contenteditable', 'false');
                    }}

                    onExecCommand={async (evt, ed) => {
                        if (evt.command == "mceUpdateImage") {// this where the image gets processed after the save button is clicked on the image control
                            let blob = await fetch(evt.value.src).then(r => r.blob());
                            props.inlineImage(blob, evt.value.src, evt.value.alt, evt.value.title)
                            // editorRef.current?.insertContent("<img src='"+evt.value.src+"' title='"+evt.value.title+"' alt='"+evt.value.alt+"' />")
                        }
                        else if (evt.command == "mceInsertContent") {// this where the image gets processed after the save button is clicked on the image control
                            if (evt.value.content != undefined) {
                                if (evt.value.content.indexOf("blob:http://") > 0 || evt.value.content.indexOf("file://") > 0) { //this is to prevent double pictures from being added by tinymce
                                    evt.preventDefault();
                                    evt.stopImmediatePropagation();
                                    evt.stopPropagation();

                                    return false;

                                }

                            } else if (evt.value.indexOf("blob:http://") > 0) { // this is to update the indexDB with the latest image that was added
                                props.onChange(editorRef.current?.dom.doc.documentElement.outerHTML);

                            }

                        }
                    }}

                    initialValue={props.initialValue}
                    init={{
                        setup: function (ed) {
                            ed.on('init', function (args) {
                                ed.editorContainer.style.height = (window.innerHeight - 442) + "px"
                                ed.editorContainer.focus();
                            });
                            ed.on('mousedown', handleInsideClick);
                            ed.on('paste', async function (e) {
                                if (e.clipboardData!.files!.length > 0) {
                                    await processFiles(e.clipboardData!.files!)
                                }
                            });
                            ed.on('PastePreProcess', function (e) { // there is a bug that when you copy an image from windows picture preview it added a blank image, this fixed that
                               if(e.content.indexOf('<img src=')>0){ // only apply this if its an image that is being pasted
                                e.stopPropagation();
                                e.stopImmediatePropagation();
                                e.preventDefault();
                                return false;}
                            });

                        },
                        // newline_behavior: 'linebreak',
                        color_map_foreground: HtmlEditorConfig.TextColors,
                        color_map_background: HtmlEditorConfig.BackColors,
                        custom_colors: false,
                        branding: false,
                        statusbar: false,
                        menubar: false,

                        plugins: [
                            'link', 'image',
                            'table ', 'lists',
                        ],
                        visual: false,
                        base_url: '/tinymce/',
                        convert_urls: false,
                        relative_urls: false,
                        remove_script_host: false,
                        automatic_uploads: false,
                        image_title: true,
                        images_replace_blob_uris: true,
                        images_reuse_filename: true,
                        paste_data_images: false,
                        file_picker_callback: function (callback, value, meta) { // launch the file picker
                            var input = document.createElement("input");
                            input.setAttribute("type", "file");
                            input.setAttribute("accept", "image/*");
                            input.click();
                            input.onchange = function (e: any) {
                                var file = e.target.files[0];
                                var reader = new FileReader();
                                reader.onload = function () {
                                    /*
                                      Note: Now we need to register the blob in TinyMCEs image blob
                                      registry. In the next release this part hopefully won't be
                                      necessary, as we are looking to handle it internally.
                                    */
                                    var id = Math.random().toString(16).slice(2);
                                    var blobCache = editorRef.current!.editorUpload.blobCache;
                                    var base64 = (reader.result as string).split(',')[1];
                                    var blobInfo = blobCache.create(id, file, base64);
                                    blobCache.add(blobInfo);
                                    callback(blobInfo.blobUri(), { alt: file.name, title: id }); // this function applies the values to fields on the image control
                                };
                                reader.readAsDataURL(file);
                            };

                        }, browser_spellcheck: true, contextmenu: 'link',
                        toolbar: HtmlEditorConfig.Toolbar,
                        content_style: 'body {  font-size:10pt; font-family: "' + HtmlEditorConfig.DefaultFont + '" } h1{font-size:26px !important;} p {margin:0px;padding:0px;}' +
                            ' h2{font-size:24px !important;} ' +
                            ' h3{font-size:22px !important;} ' +
                            ' h4{font-size:20px !important;} ' +
                            ' h5{font-size:18px !important;} ' +
                            ' h6{font-size:16px !important;} ' +
                            ' my_class{background:yellow !important} ' +
                            '::-webkit-scrollbar { width: 5px;  height: 5px;}' +
                            '::-webkit-scrollbar-track,::-webkit-scrollbar-track {    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);}' +
                            '::-webkit-scrollbar-thumb,::-webkit-scrollbar-thumb {    background-color: #A5AAB3;    outline: 0px solid #A5AAB3;}' +
                            ' p.EmsNormal {margin:0px;padding:0px; font-family: "' + HtmlEditorConfig.DefaultFont + '"} ' +
                            ' h1.EmsNormal{font-size:26px !important; font-family: "' + HtmlEditorConfig.DefaultFont + '"} ' + // the EmsNormal class is added for emails that are replied to in Outlook and other mail apps
                            ' h2.EmsNormal{font-size:24px !important; font-family: "' + HtmlEditorConfig.DefaultFont + '"} ' +
                            ' h3.EmsNormal{font-size:22px !important; font-family: "' + HtmlEditorConfig.DefaultFont + '"} ' +
                            ' h4.EmsNormal{font-size:20px !important; font-family: "' + HtmlEditorConfig.DefaultFont + '"} ' +
                            ' h5.EmsNormal{font-size:18px !important; font-family: "' + HtmlEditorConfig.DefaultFont + '"} ' +
                            ' h6.EmsNormal{font-size:16px !important; font-family: "' + HtmlEditorConfig.DefaultFont + '"} ' +
                            ' td {height: 20px  }' // this is needed because its sets a minimum height of the cell so empty cells render correctly in outlook
                        ,
                        style_formats: [
                            { title: 'default', inline: 'span', styles: { fontSize:"10pt",fontWeight:"normal"} },
                            { title: 'Heading 1', inline: 'span', styles: { fontSize:"26px",fontWeight:"bold",display:"block"} },
                            { title: 'Heading 2', inline: 'span', styles: { fontSize:"24px",fontWeight:"bold",display:"block"} },
                            { title: 'Heading 3', inline: 'span', styles: { fontSize:"22px",fontWeight:"bold",display:"block"} },
                            { title: 'Heading 4', inline: 'span', styles: { fontSize:"20px",fontWeight:"bold",display:"block"} },
                            { title: 'Heading 5', inline: 'span', styles: { fontSize:"18px",fontWeight:"bold",display:"block"} },
                            { title: 'Heading 6', inline: 'span', styles: { fontSize:"16px",fontWeight:"bold",display:"block"} },
                          ],
                        toolbar_items_size: 'small',
                        font_family_formats: HtmlEditorConfig.Fonts.flat(1).toString(),
                        skin: 'tinymce-5',
                        icons: 'default',
                        block_formats: 'Paragraph=p;Heading 2=h2;Heading 3=h3;Heading 4=h4;',
                        table_advtab: false,
                        table_row_advtab: false,
                        table_cell_advtab: false,
                    }
                    }

                >

                </Editor>


            }

        </>
    )
}

export default HtmlEditor;