import '../../App.css';
import '../template/styles/generic-components.scss';
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from '../../state/store'
import './index.scss';
import FullWidth from '../template/fullWidth/fullWidth'
import RestrictedWidth from '../template/restrictedWidth/restrictedWidth'
import { useEffect, useState } from 'react';
import { setEnquiriesVisibility, setQueuesVisibility, setRestrictedView, setSearchResultsVisibility } from '../../state/siteSlice'
import { getSessionValue, saveSessionValue } from '../../utilities/localStore/calls';
import { recreateWorkersFromSession } from '../../utilities/userProcessing';
import { switchFirstTab } from '../../state/tabRow';
import CreateNote from '../CreateNoteForm';
import { CreateNoteContext } from '../../contexts/create-note.context';
import { ChangeEnquiryOwnershipContext } from '../../contexts/changeEnquiryOwnership.context';
import ChangeEnquiryOwnership from '../ChangeEnquiryOwnershipForm';
import { setLastCommunication } from '../../Logic/LastTouched/comm';
import { clearDraftIds } from '../../Logic/GenericComms/createNote';
import { GlobalModalContext } from '../../contexts/GlobalModalContext';
import GlobalModals, { iGlobalModalsProps } from '../GlobalModals';
import { defaultReset, uploadErrorList } from '../../error-boundary/logerror'; 
import useInterval from "../../hooks/useInterval";
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from '../../error-boundary/errorFallback';
import { RemoveEnquiriesContext } from '../../contexts/removeEnquiries.context';
import RemoveEnquiries from '../RemoveEnquiries/Index';
import { ChangeEnquiryPriorityContext } from '../../contexts/changeEnquiryPriority.context';
import ChangeEnquiryPriority from '../ChangeEnquiryPriority';
import { iEnquiry } from '../../interfaces/iEnquiry';
import { gcMaildrafts } from '../../utilities/localStore/indexDBCalls';
import { ReplaceEnquiryOwnershipContext } from '../../contexts/replaceEnquiryOwnership.context';
import Confirm from '../Generic/ConfirmDialog';
import { EnquiryPropertiesContext } from '../../contexts/enquiry-properties.context';
import { EnquiryPropertiesModal } from '../EnquiryProperties/EnquiryPropertiesModal';
import { getLastTouched, saveLastTouched } from '../../Logic/LastTouched/misc';
import { EnquiryGridSelectionContext, gridSelectionProperties } from '../../contexts/enquiry-grid-selection.context';
import { TakeOwnership } from '../../Logic/EmsAPI/Enquiries';
import { LoadMailbox, LoadSearchResults, LoadUnresolved, ReloadActiveList } from '../../Logic/Enquiries/Load';
import { SetLastTouchedEnquiry } from '../../interfaces/enquiryList';
const initialModalState: iGlobalModalsProps = {};

function Index() {
  const SiteInformation = useSelector((state: RootState) => state.SiteInformation);
  const User = useSelector((state: RootState) => state.UserInformation);
  const permissions = useSelector((state: RootState) => state.permissions);
  const dispatch = useAppDispatch();
  const [showErrorModal, setShowErrorModal] =  useState<{errorLogId?: string | undefined, message: string}|undefined>(undefined);
  const [createNote, setCreateNote] = useState({shown: false, enquiryId: 0});
  const [changeEnquiryOwnership, SetChangeEnquiryOwnershipContext] = useState<{enquiries: Array<iEnquiry>,shown: boolean}>();
  const [replaceEnquiryOwnership, setReplaceEnquiryOwnership] = useState<{enquiries: Array<iEnquiry>, shown: boolean}>();
  const [enquiryProperties, setEnquiryProperties] = useState({ shown: false, enquiryId: 0 });
  const [removeEnquires, SetRemoveEnquiriesContext] = useState<{enquiries: Array<iEnquiry>,shown: boolean}>();
  const [changeEnquiryPriority, SetChangeEnquiryPriorityContext] = useState<{enquiries: Array<iEnquiry>,shown: boolean}>();
  const [modalState, setModalState] = useState(initialModalState);
  const [selectionProperties, SetEnquiryGridSelectionProperties] = useState<gridSelectionProperties>({
    multiSelect:false, selectedEnquiries:[]
});
    useInterval(() => uploadErrorList(), 10 * 60 * 1000);
    
useEffect(() => {
   gcMaildrafts();
   window.addEventListener('resize', HandleResize);
   return () => window.removeEventListener('resize', HandleResize);
 },[])

  async function HandleResize(e: any) {
    dispatch(setRestrictedView(e.target.innerWidth < 1066));
  }

  useEffect(() => {
    if (window.innerWidth < 1066 && SiteInformation.restrictedView == false) {
      dispatch(setRestrictedView(true));

    } else if (window.innerWidth > 1065 && SiteInformation.restrictedView == true) {
      dispatch(setRestrictedView(false));
      
    }
  }, []);

    useEffect(() => {
        (async () => {
            const iLastTouched = getLastTouched();
            if (permissions.Tenant.ViewMyEnquiries) {
                iLastTouched.MyEnquiries.ActiveList = "Unresolved";
                iLastTouched.lastActiveListType = "Unresolved";
                saveLastTouched(iLastTouched);
    
                await LoadUnresolved();
    
                dispatch(switchFirstTab("My Enquiries"));
                dispatch(setEnquiriesVisibility());
            }
            else if (permissions.AnyMailbox.ViewEnquiryList) {
                const mailbox = iLastTouched.Queue.ActiveList;
                iLastTouched.lastActiveListType = mailbox.toString();
                saveLastTouched(iLastTouched);

                await LoadMailbox(mailbox);

                dispatch(switchFirstTab("Mailboxes"));
                dispatch(setQueuesVisibility());
            } else {
                iLastTouched.lastActiveListType = "Results";
                saveLastTouched(iLastTouched);

                await LoadSearchResults();

                dispatch(switchFirstTab("Search Results"));
                dispatch(setSearchResultsVisibility())
            }
        })();
  }, []);

  async function onNoteCreated() {
    setCreateNote({enquiryId: 0, shown: false});
    setLastCommunication(createNote.enquiryId, 0);
    SetLastTouchedEnquiry(createNote.enquiryId, 0);
    await ReloadActiveList();
}
async function onChangeOwnershp() {
  await ReloadActiveList();

  setLastCommunication(0, 0);
  SetChangeEnquiryOwnershipContext({enquiries:[], shown: false});
  if(selectionProperties.multiSelect){
      const LastTouched = getLastTouched();
      if(
          User.preferences.general.enquiryList.multiSelectMode=="multi-preview" || 
          (LastTouched.lastActiveListType=="Resolved" || LastTouched.lastActiveListType=="Unresolved")
          )
      {
          SetEnquiryGridSelectionProperties({ multiSelect : false, selectedEnquiries : [] });
      }
  }
}

async function onRemoveEnquiry() {
  await ReloadActiveList();

  if(selectionProperties.multiSelect){
        SetEnquiryGridSelectionProperties(currentState=>{
            currentState = JSON.parse(JSON.stringify(currentState));
            currentState.multiSelect =  false;
            currentState.selectedEnquiries =  []
        return currentState;
        }) ;
}
  SetRemoveEnquiriesContext({enquiries:[], shown: false});
}
async function onChangeEnquiryPriority() {
    await ReloadActiveList();
    
    SetChangeEnquiryPriorityContext({enquiries:[], shown: false});
    if(selectionProperties.multiSelect){
        if(User.preferences.general.enquiryList.multiSelectMode=="multi-preview"){
            SetEnquiryGridSelectionProperties(currentState=>{
                currentState = JSON.parse(JSON.stringify(currentState));
                currentState.multiSelect = false;
                currentState.selectedEnquiries = [];
            return currentState;
            }) ;
        }
}

}
function onPropertiesUpdated() {
    ReloadActiveList();
    setEnquiryProperties({enquiryId: 0, shown: false});
}


useEffect(() => { clearDraftIds(); }, [])

  if (User.userID > 0) 
  {
 
    if(getSessionValue<boolean>("loggedIn")==true){
        recreateWorkersFromSession(null).catch((err)=>{});
      }else
      {
       saveSessionValue("loggedIn",true);
      }
  }

  return (
    <EnquiryPropertiesContext.Provider value={(enquiryId: number) => { setEnquiryProperties({enquiryId, shown: true}) }}>
    <ChangeEnquiryPriorityContext.Provider value={(enquiries: Array<iEnquiry>) => { SetChangeEnquiryPriorityContext({enquiries:enquiries, shown: true}) }}>
    <RemoveEnquiriesContext.Provider value={(enquiries: Array<iEnquiry>) => { SetRemoveEnquiriesContext({enquiries:enquiries, shown: true}) }}>
    <ReplaceEnquiryOwnershipContext.Provider value={(enquiries: Array<iEnquiry>) => setReplaceEnquiryOwnership({ enquiries: enquiries, shown: true })}>
    <ChangeEnquiryOwnershipContext.Provider value={(enquiries: Array<iEnquiry>) => { SetChangeEnquiryOwnershipContext({enquiries:enquiries, shown: true}) }}>
    <CreateNoteContext.Provider value={(enquiryId: number) => { setCreateNote({enquiryId, shown: true}) }}>
    <GlobalModalContext.Provider value={(newModalState) => { setModalState(newModalState)}}>
    <EnquiryGridSelectionContext.Provider value={ {selectionProperties , SetEnquiryGridSelectionProperties: SetEnquiryGridSelectionProperties}}>
    <>
    <ErrorBoundary  FallbackComponent={ErrorFallback} onReset={defaultReset}>
      { (
        !SiteInformation.restrictedView ? 
        <FullWidth></FullWidth> : <RestrictedWidth></RestrictedWidth>
      )  }
      </ErrorBoundary>
      <div style={{
        height: "100%", width: "100%", zIndex: 2147483647,
        position: "absolute", top: "0px", left: "0px",
        display: "none", cursor: "wait",
        justifyContent: "center"
      }} id="loader">
          <div className='notice loading'>Still busy. Please wait...</div>
      </div>
        {createNote.shown && <CreateNote
          enquiryId={createNote.enquiryId}
          onCancel={() => { setCreateNote({ enquiryId: 0, shown: false }) }}
          onCreated={onNoteCreated}
          onError={(error, errorLogId) => { 
            setCreateNote({enquiryId:0, shown: false})
            setShowErrorModal({errorLogId:errorLogId,message:error})
          }}
        />}
        {changeEnquiryOwnership?.shown && <ChangeEnquiryOwnership
          enquiries={changeEnquiryOwnership.enquiries}
          onCancel={() => { SetChangeEnquiryOwnershipContext({ enquiries:[], shown: false }) }}
          onCreated={onChangeOwnershp}
          onError={(error, errorLogId) => { 
            SetChangeEnquiryOwnershipContext({ enquiries:[], shown: false });
            setShowErrorModal({errorLogId:errorLogId,message:error});
          }}
        />}
        {removeEnquires?.shown && <RemoveEnquiries
          enquiries={removeEnquires.enquiries}
          onCancel={() => { SetRemoveEnquiriesContext({ enquiries:[], shown: false }) }}
          onCreated={onRemoveEnquiry}
          onError={(error, errorLogId) => { 
            SetRemoveEnquiriesContext({ enquiries:[], shown: false })
            setShowErrorModal({errorLogId:errorLogId,message:error})
          }}
        />}
         {changeEnquiryPriority?.shown && <ChangeEnquiryPriority
          enquiries={changeEnquiryPriority.enquiries}
          onCancel={() => { SetChangeEnquiryPriorityContext({ enquiries:[], shown: false }) }}
          onCreated={onChangeEnquiryPriority}
          onError={(error, errorLogId) => { 
            SetChangeEnquiryPriorityContext({ enquiries:[], shown: false });
            setShowErrorModal({errorLogId:errorLogId,message:error});
          }}
        />}
        {replaceEnquiryOwnership?.shown && <Confirm
          messageType='warn'
          title='Warning'
          onConfirm={async () => {
            const response = await TakeOwnership(replaceEnquiryOwnership.enquiries.map(x=> x.id));
            setReplaceEnquiryOwnership({ enquiries: [], shown: false });
            if(!response.isOk){
                setShowErrorModal({errorLogId:response.errorLogID, message: "Unable to change ownership."})
                return;
            }
            
            if(selectionProperties.multiSelect && User.preferences.general.enquiryList.multiSelectMode=="multi-preview"){
                SetEnquiryGridSelectionProperties({ multiSelect: false, selectedEnquiries: [] });
            }

            await ReloadActiveList();
          }}
          onCancel={() => setReplaceEnquiryOwnership({ enquiries: [], shown: false })}
        >
          <>
            {(replaceEnquiryOwnership.enquiries.length == 1 ?
            `This enquiry is already owned by ${replaceEnquiryOwnership
              .enquiries[0]
              ?.owners[0]
              .firstname} ${replaceEnquiryOwnership
                .enquiries[0]
                ?.owners[0]
                .lastname
              }. ` : "Enquiries you have selected are owned by someone else.") +
              "Are you sure you want the current owner(s) to be replaced with you as the new owner?"}

          </>
        </Confirm>}
        {enquiryProperties.shown && <EnquiryPropertiesModal
          enquiryId={enquiryProperties.enquiryId}
          onCancel={() => { setEnquiryProperties({ enquiryId: 0, shown: false }) }}
          onCreated={onPropertiesUpdated}
          onError={(error, errorLogId) => { 
            setEnquiryProperties({ enquiryId: 0, shown: false });
            setShowErrorModal({errorLogId:errorLogId,message:error})
          }}
        />}
        {showErrorModal  && <Confirm
          messageType='err'
          title='Error!'
          onConfirm={()=> {
                setShowErrorModal(undefined);
                ReloadActiveList();
            }
        }
        >{
          <>
               <p>{showErrorModal.message}</p> 
               <p>Please try again or contact support if the issue persists.</p>
               {showErrorModal.errorLogId && <p>Reference Number: <b>{showErrorModal.errorLogId}</b></p>}
          </>}
        </Confirm> }
        
        <GlobalModals {...modalState} />
    </>
    </EnquiryGridSelectionContext.Provider>
    </GlobalModalContext.Provider>
    </CreateNoteContext.Provider>
</ChangeEnquiryOwnershipContext.Provider>
</ReplaceEnquiryOwnershipContext.Provider>
</RemoveEnquiriesContext.Provider>
</ChangeEnquiryPriorityContext.Provider>
</EnquiryPropertiesContext.Provider>
  );
}

export default Index;