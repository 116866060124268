import { useContext, useEffect, useRef, useState } from "react";
import { BsList } from "react-icons/bs";
import useOutsideClick from "../../hooks/useOutsideClick";
import { EnquiryMenuProps } from "../../interfaces/menu";
import { EnquiryMenuItems } from ".";
import { EnquiryGridSelectionContext } from "../../contexts/enquiry-grid-selection.context";
import { RootState } from "../../state/store";
import { useSelector } from "react-redux";
import Confirm from "../Generic/ConfirmDialog";
import Statuses from "../../interfaces/Statuses";

export function EnquiryBurgerMenu({ enquiryIds }: EnquiryMenuProps) {

    const [barMenuActive, setBarMenuActive] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null)
    useOutsideClick(menuRef, () => setBarMenuActive(false));
    const {selectionProperties} = useContext(EnquiryGridSelectionContext);
    const enquiriesCache = useSelector((state: RootState) => state.EnquiryCache.data);
    const [multiSelectEnquiriesInUserProcessing, setmultiSelectEnquiriesInUserProcessing] = useState(false);
    useEffect(()=>{
        if(!selectionProperties.multiSelect){
            setmultiSelectEnquiriesInUserProcessing(false); //if i dont set this then the selection is remembered when we go between multi and single instead of being reset
            return;
        }
        if(enquiriesCache.find(x=> (enquiryIds.includes(x.id) && x.status == Statuses.Processing.ID))){
            setmultiSelectEnquiriesInUserProcessing(true);
        }else{
            setmultiSelectEnquiriesInUserProcessing(false);
        }
    })
 
    return (
        <>

        <div className={`g-dropdown-basic${barMenuActive ? "-selected" : ""}`} ref={menuRef}>
            <div tabIndex={0} onClick={() => setBarMenuActive(b => !b)} className={` icon-button${barMenuActive ? "-selected" : ""} `}>
               <BsList style={{width:"14px", height:"14px"}} />
            </div>
            {(barMenuActive && !multiSelectEnquiriesInUserProcessing) && <div
                style={{ position: "absolute", top: 30, left: -188 }}
            >
                <EnquiryMenuItems onHide={()=>setBarMenuActive(false)} enquiryIds={enquiryIds} menuStyle={"menu"} />
            </div>
            }

            {(barMenuActive && multiSelectEnquiriesInUserProcessing)&&(
            <Confirm
            title='Warning'
            onConfirm={() => {
                setmultiSelectEnquiriesInUserProcessing(false);
                setBarMenuActive(false)
            }}
        >
            <p>One or more enquiries you selected are currently in User Processing. Please deselect it to perform your action.</p>

            </Confirm>
        )}
        </div> 
        </>
    )
}