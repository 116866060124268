import { useCallback, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { ErrorBoundary } from 'react-error-boundary';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { ErrorFallback } from '../../../error-boundary/errorFallback';
import { defaultReset } from '../../../error-boundary/logerror';
import { getPreference, iPreference, savePrefence } from '../../../interfaces/preference';
import { iWizard, iWizards } from '../../../interfaces/wizard';
import { removeNewEnquiryId, removePopped } from '../../../Logic/Enquiries/activateWizard';
import { ReloadActiveList } from '../../../Logic/Enquiries/Load';
import { setLastCommunication } from '../../../Logic/LastTouched/comm';
import { startDrag } from '../../../Logic/Resize/horizontal';
import { setWorkflowPopped, setWorkflowVisible } from '../../../state/secondColSlice';
import { RootState, useAppDispatch } from '../../../state/store';
import { removeTab } from '../../../state/tabRow';
import { getSessionValue, saveSessionValue } from '../../../utilities/localStore/calls';
import { closeConnection } from '../../../utilities/userProcessing';
import CommPreview from '../../Email/switch';
import Workflow from '../../Workflow';
import DefaultView from './defaultLayout';

function WorkflowLayout(props: any) {
    const dispatch = useAppDispatch();
    const UserInfo = useSelector((state: RootState) => state.UserInformation);
    const internetConnection = useSelector((state: RootState) => state.Connection);
    const activeEnquiry = useSelector((state: RootState) => state.tabRow.tabs).find(i => i.active == true);
    const enquiriesCache = useSelector((state: RootState) => state.EnquiryCache.data);
    const enQ  = enquiriesCache.find(x=>x.id==activeEnquiry?.enquiryId) 

    const handleMessage = useCallback(async (event: MessageEvent<any>) => {
        if (window.location.href.startsWith(event.origin)) {
            if (event.data?.msg && event.data.msg == 'close') {
                removePopped(event.data.EnquiryId);
                await closeConnection(event.data.EnquiryId);
                await ReloadActiveList();
                dispatch(setWorkflowPopped(false));
                dispatch(setWorkflowVisible(false));
            }
            else if (event.data?.msg && event.data.msg == 'cancel') {
                removeNewEnquiryId(event.data.EnquiryId);
                await ReloadActiveList();
                removePopped(event.data.EnquiryId);
                dispatch(setWorkflowPopped(false));
                dispatch(setWorkflowVisible(false));
                
            }
            else if (event.data?.msg && event.data.msg == 'afterAction') {
                removePopped(event.data.EnquiryId);
                removeNewEnquiryId(event.data.EnquiryId);
                setLastCommunication(event.data.EnquiryId, 0);
                await ReloadActiveList();
                dispatch(setWorkflowPopped(false));
                dispatch(setWorkflowVisible(false));
            }
        }
    }, []);

    window.addEventListener('message', handleMessage, false);

    async function popWorkflow() {
       if (enQ !== undefined) {
    await closeConnection(enQ.id);
        dispatch(setWorkflowPopped(true));
        dispatch(setWorkflowVisible(false));
        let allWizards = getSessionValue<iWizards>("poppedWizards");
        let wizard: iWizard = {
            popped: true,
            id: enQ.id,
            enquiry: enQ,
        };
        if (allWizards == null) {
            allWizards = {
                wizards: [wizard]
            };
            saveSessionValue("poppedWizards", allWizards);
        } else {
            let enQ1 = allWizards.wizards?.find(x => x.id == (enQ?.id))?.enquiry; // ts let enQ
            if (enQ1 == undefined) {
                allWizards.wizards.push(wizard);
            }
            saveSessionValue("poppedWizards", allWizards);
        }

        var windowA = window.open(window.location.href + "/Workflow/" + enQ.id, "popped-wizard-" + enQ.id, "location=no;toolbar=yes,scrollbars=yes,resizable=yes,top=10,left=10,width=700,height=600");
        dispatch(removeTab(enQ.id));
       }
    }
  
    useEffect(() => {
        let preference: iPreference = getPreference();
        if (preference.interface.LeftPane == undefined || preference.interface.RightPane == undefined) {
            preference.interface.LeftPane = 50;
            preference.interface.RightPane = 50
            savePrefence(preference);
        }
        document.getElementById("leftPane")!.style.width = preference.interface.LeftPane + "%";
        document.getElementById("rightPane")!.style.width = preference.interface.RightPane + "%";
    }, []);

    return (
        <>
            {!enQ?  <DefaultView></DefaultView>  : <>
            <Row className="secondRowWorkflow">
                <div>
                    Enquiry Wizard - {enQ.enquiryReference + " - " + enQ.subject}
                </div>
                <div>
                    {props.FullWidth ? <div onClick={() => { popWorkflow() }} style={{ display: enQ ? "block" : "none", textAlign: "right",   marginRight:"11px" }}    className="icon-button">
                        <BsBoxArrowUpRight style={{width:"12px", height:"12px"}}></BsBoxArrowUpRight> 
                    </div> : null}
                </div>
            </Row>

            <div style={{position:"relative"}} className='MainFlexRow' id="mainPane">
                <div id="leftPane" className='firstColFullWidth ' style={{ overflowY: "auto",paddingLeft:"10px" }}>
                    <ErrorBoundary
                        FallbackComponent={ErrorFallback}
                        onReset={defaultReset} 
                    >
                      <CommPreview FullWidth={true} enquiry={enQ} hasActivateWiz={false}/>
                    </ErrorBoundary>
                </div>
                <div id="rightPane" className='secondCol ' style={{paddingRight:"10px", display:"flex"}}>
                    <div id="resize" onMouseDown={(event) => startDrag(event)}  ></div>
                    <ErrorBoundary
                        FallbackComponent={ErrorFallback}
                        onReset={defaultReset} 
                    >
                        <Workflow Popped={false} FullWidth={true} Enquiry={enQ} ></Workflow>
                    </ErrorBoundary>
                </div>
            </div>
            </>}
        </>
    )

};

export default WorkflowLayout;