import { Fragment, useEffect, useRef } from "react";
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import React from "react";
import './styles.scss'
import Statuses from '../../../interfaces/Statuses';
export interface dataTableProps<TData> {
    data: Array<TData & { id?: number }>,
    theme: string,
    columns: dataColumn<TData>[],
    uniqueIdentifiyer: (items:TData) => string,
    getCustomClass?: (items:TData) => string,
    selectedItems: string[],
    onRowClick: (data: string, clickEVT: React.MouseEvent) => void;
    onRowDblClick?:  (data: string) => void;
    contextMenu: (key: string, x: number, y: number) => void
    keyPress: (e:React.KeyboardEvent<HTMLTableRowElement>) => void
    spacerRow?: number,
    selectedItem:  string,
    lastItemInteracted:number,
}

export interface dataColumn <TData>{
    title: JSX.Element,
    toolTip?:string,
    getDataToolTip? : (data: TData) => string,
    key:string,
    getData: (data: TData) => {},
    width: string,
    minWidth: string,
    maxWidth?: string,
    canResize: boolean,
    sort: {
        direction: "asc" | "desc" | undefined,
        sortMethod: Function,
        displayIcon: boolean,
    } | undefined,
    textAlignment: "left" | "center" | "right",
}

function DataTable<T>({ data, theme, columns, uniqueIdentifiyer, selectedItems, onRowClick,
    contextMenu, keyPress, spacerRow, onRowDblClick, getCustomClass,selectedItem,lastItemInteracted }: dataTableProps<T>) {
    const dataTable = useRef<HTMLTableElement>(null);
    selectedItems = [...selectedItems];
    data = JSON.parse(JSON.stringify(data));
    columns = [...columns];
 
    useEffect(() => {
        const selectedItemIndex = data.findIndex(x=> x.id?.toString()== selectedItem);
        if(selectedItems.length==0 && selectedItemIndex==undefined){ return;} // this means that its in single selection mode.
        let lstEnq = data[selectedItemIndex];
        if (selectedItems.length > 0) { // reposition the scroll position if returning to the grid;
            lstEnq = data[lastItemInteracted];
        }
        if (lstEnq == undefined) { return; }
            let selectedRowTR = document.getElementById("row_" + lstEnq.id);
            // scroll if part of elt not showing
            selectedRowTR?.scrollIntoView({
                behavior: "smooth",
                block: "nearest"
            });
            selectedRowTR?.focus(); // i need to do this to give the rows focus so that the key handle up and down works
    }, [lastItemInteracted,selectedItem]);
     
    const handleMouseDown = (e: any) => {
        let currentIndicator = e.currentTarget as HTMLElement;
        let colselc = document.getElementById("resizeIndication") as HTMLElement;
        const headerCell = currentIndicator.parentElement?.parentElement;
        const nextCell: any = headerCell? headerCell.nextSibling : null;

        if(!headerCell || !nextCell)
            return;

        headerCell.style.pointerEvents = "none"
        headerCell.style.userSelect = "none"
        const styles = window.getComputedStyle(headerCell);
        const startPos = {
            x: e.clientX,
            y: e.clientY,
            w: parseInt(styles.width, 10)
        };

        const resizeFunc = (evt: any) => { resizeCol({ resizeIndicator: colselc, startPos: startPos, elem: headerCell, mm: evt }) };
        const mouseUpFunc = (evt: any) => {
            let currentCursorPosition = parseInt(colselc.style.left.replace("px", ""));
         
            if (currentCursorPosition < startPos.x) {
                let cellMinwidth = headerCell.style.minWidth ? parseInt(headerCell?.style.minWidth.replace("px", "")) : 0;
                let nextcellMaxwidth = nextCell?.style.maxWidth ? parseInt(nextCell?.style.maxWidth.replace("px", "")) : undefined;
                let currentCellStartWIdth = headerCell.clientWidth;
                let newWidth = Math.round(((headerCell.clientWidth - (startPos.x - currentCursorPosition) + 0)));
                let nextCellNewWidth = Math.round(((nextCell.clientWidth + (startPos.x - currentCursorPosition) + 0)));
                if(newWidth<cellMinwidth){
                    newWidth=cellMinwidth
                    nextCellNewWidth = Math.round(((nextCell.clientWidth + (currentCellStartWIdth - cellMinwidth)  )));
                };
                if(newWidth!=headerCell.clientWidth){
                if (newWidth >= cellMinwidth ) {
                    if(nextcellMaxwidth){
                            if(nextCellNewWidth <= nextcellMaxwidth){
                            headerCell.style.width = newWidth + "px"
                            nextCell.style.width = nextCellNewWidth + "px";}
                    }else{
                            headerCell.style.width = newWidth + "px"
                            nextCell.style.width = nextCellNewWidth + "px";
                    }
                }}
            }
            else {
                let cellMinwidth = nextCell?.style.minWidth ? parseInt(nextCell?.style.minWidth.replace("px", "")) : 0;
                let newWidth = Math.round(((nextCell.clientWidth - (currentCursorPosition - startPos.x) + 0)));
                let currentCellNewWidth = Math.round(((headerCell.clientWidth + (currentCursorPosition - startPos.x) + 0))) ;
                let currentCellMaxwidth = headerCell.style.maxWidth ? parseInt(headerCell.style.maxWidth.replace("px", "")) : undefined;

                if (newWidth >= cellMinwidth) {
                    if(currentCellMaxwidth){
                        if(currentCellNewWidth<=currentCellMaxwidth){
                            headerCell.style.width = Math.round(((headerCell.clientWidth + (currentCursorPosition - startPos.x) + 0))) + "px";
                           nextCell.style.width = Math.round(((nextCell.clientWidth - (currentCursorPosition - startPos.x) + 0))) + "px";
                        }
                    }
                    else{
                        headerCell.style.width = Math.round(((headerCell.clientWidth + (currentCursorPosition - startPos.x) + 0))) + "px";
                        nextCell.style.width = Math.round(((nextCell.clientWidth - (currentCursorPosition - startPos.x) + 0))) + "px";
                     }
                }else{
                    headerCell.style.width = headerCell.clientWidth + (nextCell.clientWidth - cellMinwidth) + "px"
                    nextCell.style.width = cellMinwidth + "px";
                }
            }
            headerCell.style.pointerEvents = "auto"
            headerCell.style.userSelect = "auto"
     

            colselc.style.display = "none";
            document.removeEventListener("mousemove", resizeFunc, false);
            document.removeEventListener("mouseup", mouseUpFunc, false);
        };
        document.addEventListener("mousemove", resizeFunc, false);
        document.addEventListener("mouseup", mouseUpFunc, false);
    };
    function resizeCol(e: any) {
        const dx = e.mm.clientX - e.startPos.x;
        e.resizeIndicator.style.left = `${e.startPos.x + dx}` + "px";
        e.resizeIndicator.style.display = "block";

    }

    let clickCount=0;

    const onClick = (evt: any, clickValue:string) => {

        clickCount++;
        
        if (clickCount == 1) {
            onRowClick(clickValue, evt)
        }

        setTimeout(async () => {
            if (clickCount > 1) {
                onRowDblClick ?  onRowDblClick(clickValue) : onRowClick(clickValue,evt);
            }
            clickCount = 0;
        }, 300);
    }
    
    return (
        <>
            <div id="resizeIndication" className="selectResizer"></div>
            {data.length > 0 &&
                <div style={{ scrollPaddingTop:"30px", overflowY: "auto", width: "100%", height: "100%", }} id="dataTableScrollDiv">
                    <table width="100%" cellPadding={0} cellSpacing={0} className={theme + " dataTable"} ref={dataTable} id="asdasdW">
                        <tr className="tableHeader" style={{ zIndex: "1500" }}>
                            {columns.map((item, index) => {

                                let colWidths = { "width": item.width,  "minWidth": item.minWidth, maxWidth: item.maxWidth? item.maxWidth :"auto" } as React.CSSProperties
                               
                                return (<th key={ "headerKey_" + item.key?  item.key : index} id={"headerId_" + item.key?  item.key : index.toString()} style={colWidths} title={item.toolTip? item.toolTip :""}>
                                    <div className="headerDiv" >
                                        <div onClick={item.sort ? () => { item.sort!.sortMethod() } : undefined} style={{  }}
                                            className={item.sort ? "headerSort" : ""}
                                        ><div style={{ padding: "4px", margin: "0px" }}>{item.title}</div>
                                            {
                                                item.sort && item.sort.displayIcon && item.sort.direction == "asc" ?
                                                    <div style={{ position: "absolute", right: "10px", top: "4px", width: "10px", cursor: "pointer"}}><BsChevronUp></BsChevronUp></div> :
                                                    item.sort && item.sort.displayIcon && item.sort.direction == "desc" ?
                                                        <div style={{ position: "absolute", right: "10px", top: "4px", width: "10px",cursor: "pointer" }}><BsChevronDown></BsChevronDown></div> : ""
                                            }
                                        </div>
                                        {(columns.length - 1 && item.canResize) &&
                                            <div onMouseDown={handleMouseDown} style={{ position: "absolute", top: "1px", right: "0px", width: "5px", zIndex: "1500" }}>&nbsp;</div>}
                                    </div>

                                </th>);
                            })}</tr>

                            {
                            data.map((item, index) => {
                                const uniqVal = uniqueIdentifiyer(item);
                                let customClasses =getCustomClass? getCustomClass(item) : "";
                                customClasses  = selectedItem == uniqVal ? customClasses + " select rowClass" : customClasses + " rowClass";
                               return (<Fragment key={"r" + uniqVal}> <tr key={"row_" + uniqVal} onClick={(evt) => { onClick( evt,  uniqVal ) }} id={"row_" + uniqVal}
                                    className={customClasses}
                                    onContextMenu={(e) => {
                                        e.preventDefault();
                                        contextMenu( uniqVal, e.pageX, e.pageY) // return the unique val of the row that was clicked on
                                    }}
                                    tabIndex={index} onKeyDown={keyPress}
                                >
                                    {
                                        columns.map((col,col_index) => {
                                            const colData = col.getData(item);
                                            return <td style={{ textAlign: col.textAlignment }}
                                            key={ col_index}
                                            title={col.getDataToolTip?.(item) ??  (typeof colData==="string"?  (colData as string) : "")}
                                            >{colData}</td>
                                        })
                                    }
 
                                </tr>
                                    {spacerRow && <tr key={"sp" + uniqVal} className="spacerRow" style={{ "height": spacerRow + "px" }}><td colSpan={columns.length}></td></tr>} </Fragment>);

                            })
                        }
                    </table>
                </div>
            }
        </>
    )

}
export default DataTable;
 
