import { iEnquiry } from "../../interfaces/iEnquiry";
import { showHideLoader } from "../../utilities/loading";
import { getAdvancedSearchResults, getEnquiries, saveActiveListEnquiries, saveAdvancedSearchResults } from "../../utilities/localStore/calls";
import { sortEnquiriesByField, sortQueues, sortResolved, sortUnresolved } from "../../utilities/sort";
import store from "../../state/store";
import { updateEnquiriesCache } from "../../state/enquiriesCache";
import { getLastTouched } from "../LastTouched/misc";
import { GetAdvancedSearchResults, GetMyEnquiries, GetQueueEnquiries } from "../EmsAPI/Enquiries";
import { iEnquiryGridState } from "../../interfaces/lastTouched";
import { setActiveEnquiry } from "../../state/activeEnquiry";
import { setEmailVisible } from "../../state/secondColSlice";
import { AdvancedSearchPM } from "../../interfaces/AdvancedSearch/AdvancedSearchPM";
import { AdvancedSearchResults } from "../../interfaces/AdvancedSearch/AdvancedSearchResults";
import { MapToRequest } from "../Search";
import { setShowEnquiry } from "../../state/restrictedViewSlice";

export async function LoadActiveList(): Promise<any> {
    await LoadActiveListInternal(false);
}

export async function ReloadActiveList(): Promise<any> {
    await LoadActiveListInternal(true);
}

async function LoadActiveListInternal(reloadSearch: boolean): Promise<any> {
    const siteInformation = store.getState().SiteInformation;
    const lastTouched = getLastTouched();

    if (siteInformation.enquiriesListVisible) {
        if (lastTouched.MyEnquiries.ActiveList === "Unresolved") {
            await LoadUnresolved();
        } else {
            await LoadResolved();
        }
    } else if (siteInformation.queuesListVisible) {
        await LoadMailbox(lastTouched.Queue.ActiveList);
    } else if (siteInformation.searchResultsVisible) {
        reloadSearch ? await ReloadSearchResults() : await LoadSearchResults();
    }
}

export async function LoadActiveMyEnquiries(): Promise<any> {
    const lastTouched = getLastTouched();
    if (lastTouched.MyEnquiries.ActiveList === "Resolved") {
        await LoadResolved();
    } else {
        await LoadUnresolved();
    }
}

export async function LoadUnresolved(): Promise<any> {
    await LoadMyEnquiries(false, sortUnresolved);
}

export async function LoadResolved(): Promise<any> {
    await LoadMyEnquiries(true, sortResolved);
}

async function LoadMyEnquiries(
    getCompleted: boolean,
    defaultSort: (enquiries: iEnquiry[]) => iEnquiry[],
): Promise<any> {
    try {
        showHideLoader("flex");

        const listName = getCompleted ? "Resolved" : "Unresolved";
        const lastTouched = getLastTouched().MyEnquiries.EnquiryLists.find(l => l.Name === listName)!; // Never null: On user login sets last touched

        const response = await GetMyEnquiries(getCompleted);
        if (!response.isOk || !response.data) {
            throw new Error("Failed to retrieve enquiries.");
        }

        const enquiries = SortEnquiries(response.data.enquiries, defaultSort, lastTouched);
        const activeList = enquiries.map(x => x.id);
        saveActiveListEnquiries(activeList);

        store.dispatch(updateEnquiriesCache(enquiries));

        SetActiveEnquiry(lastTouched, activeList);
    } finally {
        showHideLoader("none");
    }
}

export async function LoadMailbox(mailboxId: number): Promise<any> {
    try {
        showHideLoader("flex");

        const lastTouched = getLastTouched()
        const queueLastTouched = lastTouched.Queue.EnquiryLists.find(l => l.Name === lastTouched.Queue.ActiveList)!; // Never null: On user login sets last touched

        const response = await GetQueueEnquiries(mailboxId);
        if (!response.isOk || !response.data) {
            throw new Error("Failed to retrieve enquiries.");
        }

        const enquiries = SortEnquiries(response.data.enquiries, sortQueues, queueLastTouched);
        const activeList = enquiries.map(x => x.id);
        saveActiveListEnquiries(activeList);

        store.dispatch(updateEnquiriesCache(enquiries));

        SetActiveEnquiry(queueLastTouched, activeList);
    } finally {
        showHideLoader("none");
    }
}

export async function ReloadSearchResults(): Promise<any> {
    var lastResults = getAdvancedSearchResults();

    LoadSearchResults(lastResults?.LastRunQuery);
}

export async function LoadSearchResults(query: AdvancedSearchPM | undefined = undefined): Promise<any> {
    try {
        showHideLoader("flex");

        var lastTouched = getLastTouched().SearchResults.EnquiryLists[0];

        let activeList: number[] = [];
        if (!query) {
            activeList = LoadSearchResultsFromCache(lastTouched);
        } else {
            activeList = await LoadSearchResultsFromAPI(lastTouched, query);
        }

        SetActiveEnquiry(lastTouched, activeList);
    } finally {
        showHideLoader("none");
    }
}

function LoadSearchResultsFromCache(lastTouched: iEnquiryGridState): number[] {
    const lastResults = getAdvancedSearchResults();

    let activeList: number[] = [];
    if (lastResults) {
        activeList = lastResults.Results;

        // Resort
        if (activeList.length > 1) {
            let enquiries = getEnquiries(activeList);

            enquiries = SortEnquiries(enquiries, x => x, lastTouched);
            activeList = enquiries.map(x => x.id);
        }
    }

    saveActiveListEnquiries(activeList);
    store.dispatch(updateEnquiriesCache([]));

    return activeList;
}

async function LoadSearchResultsFromAPI(lastTouched: iEnquiryGridState, query: AdvancedSearchPM): Promise<number[]> {
    var request = MapToRequest(query);
    const response = await GetAdvancedSearchResults(request);
    if (!response.isOk || !response.data) {
        throw new Error("Failed to retrieve enquiries.");
    }

    var searchResults: AdvancedSearchResults = {
        Query: query,
        LastRunQuery: query,
        Results: response.data.enquiries.map(x => x.id),
    }

    saveAdvancedSearchResults(searchResults);
    saveActiveListEnquiries(searchResults.Results);

    store.dispatch(updateEnquiriesCache(response.data.enquiries));

    return searchResults.Results;
}

function SortEnquiries(
    enquiries: iEnquiry[],
    defaultSort: (enquiries: iEnquiry[]) => iEnquiry[],
    gridState: iEnquiryGridState | undefined,
): iEnquiry[] {
    if (gridState?.orderBy?.orderBy) {
        return sortEnquiriesByField(enquiries, gridState.orderBy.orderBy, gridState.orderBy.desc);
    }

    return defaultSort(enquiries);
}

function SetActiveEnquiry(gridState: iEnquiryGridState | undefined, activeEnquiries: number[]) {
    const currentActiveEnquiry = store.getState().activeEnquiry.id;
    let newActiveEnquiry = gridState?.EnquiryId ?? 0;

    if (newActiveEnquiry < 1 || !activeEnquiries.includes(newActiveEnquiry)) {
        newActiveEnquiry = activeEnquiries[0] ?? 0;
    }

    if (currentActiveEnquiry !== newActiveEnquiry) {
        store.dispatch(setActiveEnquiry(newActiveEnquiry));

        const state = store.getState();
        if (newActiveEnquiry < 1) {
            if (state.secondCol.emailVisible) {
                store.dispatch(setEmailVisible(false));
            }
            if (state.restrictedView.showEnquiry) {
                store.dispatch(setShowEnquiry(false));
            }
        } else if (!state.secondCol.emailVisible) {
            store.dispatch(setEmailVisible(true));
        }
    }
}
