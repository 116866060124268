import { useState } from 'react';
import { componentProps } from '../../../interfaces/ActionStartResponse';
import '../mis.scss';
import ComboBox from '../../Generic/ComboBox';
import FindDialog, { FindOption } from '../../Generic/FindDialog';
import { BsSearch } from 'react-icons/bs';

function TextWithDropdown(props: componentProps) {
    const [showFindDialog, setShowFindDialog] = useState(false);
    const [fieldValue, setFieldValue] = useState(props.MisEntry.value)

    const handleSelectChange = (e: React.FormEvent<HTMLInputElement>) => {


    }
    function setValue(newValue: string) {
        setFieldValue(newValue);
        const sendToParent = props.returnFunc;
        sendToParent({ fieldID: props.MisEntry.fieldID, value: newValue });
    }
  
    return (
        <div className="row rowMarginControl" key={"Main" + props.MisField.id}>
            <div className='col-sm-3'><label className='g-label'>{props.MisField.label}</label></div>
            <div className='col-sm-9'>
                <div className="controlWithButton">
                    <div>
                        <ComboBox
                            comboKey={"txtWI" + props.MisField.id}
                            className={"g-TextBox-default"}
                            disabled={false}
                            value={fieldValue}
                            maxLength={512}
                            onChange={(value) => setValue(value)}
                            ref={null}
                            options={props.MisField.lookupValues!.map(x => x.name)}
                        />
                    </div>
                    <div >
                        <button onClick={() => setShowFindDialog(true)}  ><BsSearch /></button>
                    </div>
                </div>
            </div>
            {showFindDialog &&
                <FindDialog
                    onCancel={() => setShowFindDialog(false)}
                    onConfirm={(selectedValue) => {
                        if (selectedValue !== "")
                            setValue(selectedValue);

                        setShowFindDialog(false);
                    }}
                    values={props.MisField.lookupValues!.map<FindOption>(x => {
                        return {
                            label: x.name,
                            value: x.name
                        };
                    })}
                />
            }
        </div>
    );
}
export default TextWithDropdown;

