import { Fragment} from 'react';
import './Workflow.scss';
import Step from './template'
import RadioButtonRow from '../Generic/Form/RadioButtonRow';
import { EStepKey } from '../../Logic/Wokflow/stepLogic';
import { EEnquiryAction } from '../../interfaces/wizard';
import { WizardStepProps } from './stepType';
import { isNullOrWhitespace } from '../../utilities/string';
import { WizardContext } from '../../contexts/wizard-context';
import React from 'react';
 
interface Props extends WizardStepProps {
    wizardActionOption?: EEnquiryAction
}

function Step4(props: Props) {

    let { wizard, onNext, setWizard, onBack } = React.useContext(WizardContext);
    let hasEmail = isNullOrWhitespace(
        wizard
            .workflow
            .data
            .EnquirerDetails
            .Email
    );

    const enquiry = props.Enquiry
    let filteredEmailComms = props.Communications?.filter(c => c.type === "Email" && c.email?.incoming === true)??[];
    const makeNewEnabled: boolean = enquiry !== undefined && (filteredEmailComms.length>1);
    const splitEnabled: boolean = enquiry !== undefined && (filteredEmailComms.length>0);
    wizard = JSON.parse(JSON.stringify(wizard));
    const nextFunc = () => {
        if (wizard.workflow.data.EnquiryAction === undefined) {
            alert("Please choose an action");
            return;
        }
        onNext()
    }

    const backFunc = () => {
        onBack()
    }
    
const handleClick=(option:EEnquiryAction) =>{
    setWizard(currentState=>{
        currentState = JSON.parse(JSON.stringify(currentState));
        currentState.workflow.data.EnquiryAction = option ;
      return currentState;
    }) ;
 
}
    const handleDoubleClick = (chosenAction: EEnquiryAction) => {
        setWizard(currentState=>{
            currentState = JSON.parse(JSON.stringify(currentState));
            currentState.workflow.data.EnquiryAction = chosenAction;
          return currentState;
        }) ;
        nextFunc();
    }

    const onIncludeFeedbackRIFChecked = (value: boolean) => {
        setWizard(currentState=>{
            currentState = JSON.parse(JSON.stringify(currentState));
            currentState.includeFeedbackRIF = value;
          return currentState;
        });
    }

    const onIncludeFeedbackForwardChecked = (value: boolean) => {
        setWizard(currentState=>{
            currentState = JSON.parse(JSON.stringify(currentState));
            currentState.includeFeedbackForward = value;
          return currentState;
        });
    }

    const onIncludeFeedbackEscalateChecked = (value: boolean) => {
        setWizard(currentState=>{
            currentState = JSON.parse(JSON.stringify(currentState));
            currentState.includeFeedbackEscalate = value;
          return currentState;
        });
    }

    const onIncludeFeedbackPendChecked = (value: boolean) => {
        setWizard(currentState=>{
            currentState = JSON.parse(JSON.stringify(currentState));
            currentState.includeFeedbackPend = value;
          return currentState;
        });
    }
    return (

        <Fragment>
            <Step
                title="Enquiry Action"
                backF={backFunc}
                step={EStepKey.ENQUIRY_ACTION_STEP}
                nextF={nextFunc}
                Enquiry={props.Enquiry}
                Popped = {props.Popped}
                FullWidth = {props.FullWidth}
            >
                <div className="actions">
                    <div className="heading">
                        Select your action
                    </div>
                  
                    <div className="enquiry-tracter--options">
                    <div className="Subheading">
                       Email
                    </div>
                    <RadioButtonRow
                        value={EEnquiryAction.CorrespondByEmail}
                        key={EEnquiryAction.CorrespondByEmail}
                        disabled={hasEmail}
                        onClick={handleClick}
                        onDoubleClick={handleDoubleClick}
                        label='Correspond with Enquirer by Email'
                        selectedValue={props.wizardActionOption}
                        tooltip='Double click to advance'
                        infoTooltip='Reply to the enquirer without completing the enquiry'
                    />
                    <RadioButtonRow
                        value={EEnquiryAction.RespondByEmail}
                        key={EEnquiryAction.RespondByEmail}
                        disabled={hasEmail}
                        onClick={handleClick}
                        onDoubleClick={handleDoubleClick}
                        label='Respond to Enquirer by Email'
                        selectedValue={props.wizardActionOption}
                        tooltip='Double click to advance'
                        infoTooltip='Reply to the enquirer and complete the enquiry'
                    />
                    <div className="Subheading">
                       Other Medium
                    </div>
                    <RadioButtonRow
                        value={EEnquiryAction.CorrespondByOtherMedium}
                        key={EEnquiryAction.CorrespondByOtherMedium}
                        onClick={handleClick}
                        onDoubleClick={handleDoubleClick}
                        label='Correspond with other Medium'
                        selectedValue={props.wizardActionOption}
                        tooltip='Double click to advance'
                        infoTooltip='Reply to the enquirer by Medium without completing the enquiry'
                    />
                    <RadioButtonRow
                        value={EEnquiryAction.RespondByOtherMedium}
                        key={EEnquiryAction.RespondByOtherMedium}
                        onClick={handleClick}
                        onDoubleClick={handleDoubleClick}
                        label='Respond to Enquirer with other Medium'
                        selectedValue={props.wizardActionOption}
                        tooltip='Double click to advance'
                        infoTooltip='Reply to the enquiry by Medium and complete the enquiry'
                    />
                    <div className="Subheading">
                       No Action
                    </div>
                    <RadioButtonRow
                        value={EEnquiryAction.Complete}
                        key={EEnquiryAction.Complete}
                        onClick={handleClick}
                        onDoubleClick={handleDoubleClick}
                        label='Complete Enquiry'
                        selectedValue={props.wizardActionOption}
                        tooltip='Double click to advance'
                        infoTooltip='Complete without sending an email'
                    />
                    <div className="Subheading">
                        Refer
                    </div>
                    <RadioButtonRow
                        value={EEnquiryAction.RequestInternalFeedback}
                        key={EEnquiryAction.RequestInternalFeedback}
                        onClick={handleClick}
                        onDoubleClick={handleDoubleClick}
                        label='Request Internal Feedback'
                        selectedValue={props.wizardActionOption}
                        tooltip='Double click to advance'
                        infoTooltip='Send an email internally requesting feedback'
                        optionalControl={<div className='feedback-control'>
                            <input
                                type="checkbox"
                                name="action-request-internal-feedback"
                                id={`action-request-internal-feedback-${EEnquiryAction.RequestInternalFeedback}`}
                                disabled={hasEmail}
                                checked={wizard.includeFeedbackRIF && !hasEmail}
                                onChange={e => onIncludeFeedbackRIFChecked(e.target.checked)}
                            />
                            <label htmlFor={`action-request-internal-feedback-${EEnquiryAction.RequestInternalFeedback}`} style={{userSelect: 'none'}}> Include Enquirer Feedback Email</label>
                            </div>}
                    />
                    <RadioButtonRow
                        value={EEnquiryAction.ForwardToAnotherDepartment}
                        key={EEnquiryAction.ForwardToAnotherDepartment}
                        onClick={handleClick}
                        onDoubleClick={handleDoubleClick}
                        label='Forward Enquiry to another Department'
                        selectedValue={props.wizardActionOption}
                        tooltip='Double click to advance'
                        infoTooltip='Delegate the whole enquiry to another service centre'
                        optionalControl={<div className='feedback-control'>
                            <input
                                type="checkbox"
                                name="action-request-internal-feedback"
                                id={`action-request-internal-feedback-${EEnquiryAction.ForwardToAnotherDepartment}`}
                                disabled={hasEmail}
                                checked={wizard.includeFeedbackForward && !hasEmail}
                                onChange={e => onIncludeFeedbackForwardChecked(e.target.checked)}
                            />
                            <label htmlFor={`action-request-internal-feedback-${EEnquiryAction.ForwardToAnotherDepartment}`} style={{userSelect: 'none'}}> Include Enquirer Feedback Email</label>
                        </div>}
                    />
                    <RadioButtonRow
                        value={EEnquiryAction.EscalateToManager}
                        key={EEnquiryAction.EscalateToManager}
                        onClick={handleClick}
                        onDoubleClick={handleDoubleClick}
                        label='Escalate Enquiry to Manager'
                        selectedValue={props.wizardActionOption}
                        tooltip='Double click to advance'
                        infoTooltip='Escalate to a manager via email'
                        optionalControl={<div className='feedback-control'>
                            <input
                                type="checkbox"
                                name="action-request-internal-feedback"
                                id={`action-request-internal-feedback-${EEnquiryAction.EscalateToManager}`}
                                disabled={hasEmail}
                                checked={wizard.includeFeedbackEscalate && !hasEmail}
                                onChange={e => onIncludeFeedbackEscalateChecked(e.target.checked)}
                            />
                            <label htmlFor={`action-request-internal-feedback-${EEnquiryAction.EscalateToManager}`} style={{userSelect: 'none'}}> Include Enquirer Feedback Email</label>
                        </div>}
                    />
                    <RadioButtonRow
                        value={EEnquiryAction.Pend}
                        key={EEnquiryAction.Pend}
                        onClick={handleClick}
                        onDoubleClick={handleDoubleClick}
                        label='Pend Enquiry'
                        selectedValue={props.wizardActionOption}
                        tooltip='Double click to advance'
                        infoTooltip='Put enquiry on hold'
                        optionalControl={<div className='feedback-control'>
                            <input
                                type="checkbox"
                                name="action-request-internal-feedback"
                                id={`action-request-internal-feedback-${EEnquiryAction.Pend}`}
                                disabled={hasEmail}
                                checked={wizard.includeFeedbackPend && !hasEmail}
                                onChange={e => onIncludeFeedbackPendChecked(e.target.checked)}
                            />
                            <label htmlFor={`action-request-internal-feedback-${EEnquiryAction.Pend}`} style={{userSelect: 'none'}}> Include Enquirer Feedback Email</label>
                        </div>}
                    />
                    <div className="Subheading">
                       Other
                    </div>
                    <RadioButtonRow
                        value={EEnquiryAction.MakeNew}
                        key={EEnquiryAction.MakeNew}
                        onClick={handleClick}
                        onDoubleClick={handleDoubleClick}
                        label='Make a New Enquiry'
                        selectedValue={props.wizardActionOption}
                        tooltip='Double click to advance'
                        infoTooltip='This email has been incorrectly linked to an old reference number'
                        disabled={!makeNewEnabled}
                    />
                    <RadioButtonRow
                        value={EEnquiryAction.Split}
                        key={EEnquiryAction.Split}
                        onClick={handleClick}
                        onDoubleClick={handleDoubleClick}
                        label='Split Enquiry'
                        selectedValue={props.wizardActionOption}
                        tooltip='Double click to advance'
                        infoTooltip='Split the enquiry into two as it comprises of two enquiries with different duration'
                        disabled={!splitEnabled}
                    />
                    </div>
                </div>
            </Step>

        </Fragment>
    );
}

export default Step4;
