import * as React from 'react';
import DataTable, { dataColumn } from '../Generic/DataTable';
import { iEnquiry } from '../../interfaces/iEnquiry';
import { ActivateWizardModule } from '../../Logic/Enquiries/activateWizard';
import { useContextMenu } from '../../hooks/useContextMenu';
import Statuses from '../../interfaces/Statuses';
import { SortEnquiriesParams } from '../../utilities/sort';
import { useState } from 'react';
import { GlobalModalContext } from '../../contexts/GlobalModalContext';
import { BsCheck2Square, BsPaperclip, BsThermometerHalf } from 'react-icons/bs';
import { Bell } from 'react-feather';
import { GridDateTimeDisplay } from './enquiry/enquiry';
import { EnquiryBurgerMenu, EnquiryContextMenu } from '../Menu';
import { gridSelectionProperties } from '../../contexts/enquiry-grid-selection.context';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import Confirm from "../Generic/ConfirmDialog";
import { Range } from '../../utilities/methods';
export interface gridProps {
    data:iEnquiry[],
    theme: string,
    selectedEnquiry:  number,
    columns: string[],
    sortFunction: (column:string)=>{},
    sortCol:SortEnquiriesParams| undefined,
    multiSelect: boolean,
    selectedItems: string[],
    updateSelectedItems: ( selectedItems: gridSelectionProperties)=>void
}
function EnquiryGrid(props: gridProps) {
    const UserPreferences = useSelector((state: RootState) => state.UserInformation.preferences);
    const [enquiriesInUserProcessing, setEnquiriesInUserProcessing] = useState(false);
    const [shiftSelectStart, setShiftSelectStart] = useState<number>(props.data.findIndex(x=> x.id==props.selectedEnquiry)); //start index
    const [shiftSelectEnd, setShiftSelectEnd] =  useState<number>(props.data.findIndex(x=> x.id==props.selectedEnquiry)); //end index

     let AllColumns:dataColumn<iEnquiry>[] =[
        {
            title: <>&nbsp;</>,
            key:"verticalSPacer",
            getData: (data:iEnquiry) => { 
              
                switch (props.multiSelect){
              
                    default
                      :{
                            return <div className="verticalBorder" onClick={(e: React.MouseEvent<HTMLDivElement>)=>{
                                e.stopPropagation();
                                const MultiCheck = document.getElementById("multiGrid_ch_"+data.id) as  HTMLInputElement;
                                if(MultiCheck)
                                {
                                    onCheckboxClick({...e,currentTarget:{"checked": MultiCheck.checked? false: true, value:data.id},shiftKey:e.shiftKey,ctrlKey:e.ctrlKey})
                                }
                                    
                            }}>
                                <div style={{ height: "40px",  marginTop: "-20px", marginBottom: "-40px", float: "left", marginLeft: "-6px" }} >
                                    <div style={{margin:"7px 3px 0px 5px",}}>
                                        <input type="checkbox" style={{width:"12px",height:"12px"}} 
                                            id={"multiGrid_ch_"+data.id}
                                            value={data.id}
                                            className='g-Checkbox-default'
                                            onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                                                e.stopPropagation();
                                                onCheckboxClick(e);
                                                }
                                            }
                                            checked={ props.selectedItems.findIndex(x=> x==data.id.toString())<0? false:true}
                                    ></input></div>
                                </div>
                        </div>
                        break;
                    }
                    case false:{
                        return <div className="verticalBorder">
                                <div style={{ height: "40px", width: "8px", marginTop: "-20px", marginBottom: "-40px", float: "left", marginLeft: "-6px" }} >&nbsp;</div>
                            </div>
                        break;
                    }    
                }

            },
            width: "20px",
            canResize: false,
            sort: undefined,
            textAlignment: "left",
            minWidth: "10px"
        },
        {
            title: <BsThermometerHalf></BsThermometerHalf>,
            toolTip:"Priority",
            key:"priority",
            getData: (data:iEnquiry) => {
                return <div className="sla-priority-bar" style={{marginLeft:"2px"}}>
                    <div style={{ height: ((255 - data.priority) / 255.0) * 100.0 + "%" }} className="sla-priority--value">&nbsp;</div>
                </div>
            },
            width: "20px",
            canResize: false,
            sort: {
                direction: undefined,
                sortMethod: () => { props.sortFunction("priority") },
                displayIcon: false
            },
            textAlignment: "left",
            minWidth: "15px"
        },
        {
            title: <Bell size={12}></Bell>,
            key:"hasAlarm",
            toolTip:"Alarms",
            getData: (data:iEnquiry) => { return data.hasAlarm == true ? <Bell size={12}></Bell> : "" },
            width: "18px",
            canResize: false,
            sort: {
                direction: undefined,
                sortMethod: () => { props.sortFunction("hasAlarm") },
                displayIcon: false
            },
            textAlignment: "left",
            minWidth: "15px"
        },
        {
            title: <BsPaperclip></BsPaperclip>,
            key:"hasUnworkedAttachments",
            toolTip:"Unworked Attachments",
            getData: (data:iEnquiry) => { return data.hasUnworkedAttachments === true ? <BsPaperclip></BsPaperclip> : "" },
            width: "20px",
            canResize: false,
            sort: {
                direction: undefined,
                sortMethod: () => { props.sortFunction("hasUnworkedAttachments") },
                displayIcon: false
            },
            textAlignment: "left",
            minWidth: "10px"
        },

        {
            title: <>Reference</>,
            key:"enquiryReference",
            toolTip:"Reference",
            getData: (data:iEnquiry) => {
                return <span className="enquiryReference">{data.enquiryReference}</span>
            },
            width: "130px",
            canResize: true,
            sort: {
                direction: undefined,
                sortMethod: () => { props.sortFunction("enquiryReference") },
                displayIcon: true
            },
            textAlignment: "left",
           minWidth: "90px"
        },
        {
            title: <>Owner</>,
            key:"owner",
            toolTip:"Owner",
            getData: (data:iEnquiry) => { return data.owners.map((item) => { return `${item.lastname}, ${item.firstname}` }).join('; ') },
            width: "20%",
            
            canResize: true,
            sort: {
                direction: undefined,
                sortMethod: () => { props.sortFunction("owner") },
                displayIcon: true
            },
            textAlignment: "left",
            minWidth: "80px"
        },
        {
            title: <>Enquirer</>,
            key:"enquirerName",
            toolTip:"Enquirer",
            getData: (data:iEnquiry) => { return data.enquirerName },
            width: "20%",
            canResize: true,
            sort: {
                direction: undefined,
                sortMethod: () => { props.sortFunction("enquirerName") },
                displayIcon: true
            },
            textAlignment: "left",
            minWidth: "80px"
        }, 
   
        {
            title: <>Received</>,
            key:"dateReceived",
            toolTip:"Received",
            getData: (data:iEnquiry) => { return GridDateTimeDisplay(data.dateReceived )},
            width: "20%",
            canResize: true,
            sort: {
                direction: undefined,
                sortMethod: () => { props.sortFunction("dateReceived") },
                displayIcon: true
            },
            textAlignment: "left",
            minWidth: "80px"
        },
        {
            title: <>Expiry</>,
            key:"activeSlaCycle.timeout",
            toolTip:"Expiry",
            getData: (data:iEnquiry) => { return GridDateTimeDisplay(data.activeSlaCycle?.timeout? data.activeSlaCycle?.timeout :"")},
            width: "20%",
            canResize: true,
            sort: {
                direction: undefined,
                sortMethod: () => { props.sortFunction("activeSlaCycle.timeout") },
                displayIcon: true
            },
            textAlignment: "left",
            minWidth: "80px"
        }, 
   
        {
            title: <>Status</>,
            key:"status",
            toolTip:"Status",
            getData: (data:iEnquiry) => { return Statuses.Name(data.status) },
            width: "35%",
            canResize: true,
            sort: {
                direction: undefined,
                sortMethod: () => { props.sortFunction("status") },
                displayIcon: true
            },
            textAlignment: "left",
          minWidth: "80px"
        },
        {
            title: <>Subject</>,
            key:"subject",
            toolTip:"Subject",
            getData: (data:iEnquiry) => { return data.subject},
            width: "35%",
            canResize: true,
            sort: {
                direction: undefined,
                sortMethod: () => { props.sortFunction("subject") },
                displayIcon: true
            },
            textAlignment: "left",
            minWidth: "80px"
        },
       
        {
            title: <>Completed</>,
            key:"dateCompleted",
            toolTip:"Completed",
            getData: (data:iEnquiry) => { return  data.dateCompleted?  GridDateTimeDisplay(data.dateCompleted):""},
            width: "20%",
            canResize: true,
            sort: {
                direction: undefined,
                sortMethod: () => { props.sortFunction("activeSlaCycle.timeout") },
                displayIcon: true
            },
            textAlignment: "left",
            minWidth: "80px"
        },
        {
            title: <>Items</>,
            key:"workItems",
            toolTip:"Items",
            getData: (data:iEnquiry) => { return data.workItems },
            width: "50px",
            canResize: false,
            sort: {
                direction: undefined,
                sortMethod: () => { props.sortFunction("workItems") },
                displayIcon: false
            },
            textAlignment: "right",
            minWidth: "50px",
            maxWidth: "50px"
        },
    ]

    AllColumns = AllColumns.filter(col => props.columns.find(propCol => propCol === col.key));
    for (let col of AllColumns) {
        if(props.sortCol && props.sortCol.orderBy == col.key)    {
            if(col.sort){
                col.sort.direction = props.sortCol?.desc ? "desc" : "asc"
            }
        }
    }

    const showModal = React.useContext(GlobalModalContext);
    const { contextMenuState, setContextMenuState, closeContextMenu } = useContextMenu();

    const showDataTableContextMenu = (key: string, x: number, y: number) => {
        if(props.multiSelect)
            {
                if(props.selectedItems.includes(key)){ // if you righ click on a row and its not been selected dont show the menu
                    if(props.data.filter(x=> (props.selectedItems.includes(x.id.toString()))).filter(z=> z.status == Statuses.Processing.ID).length==0 ){
                        closeContextMenu();
                        setContextMenuState({ keys: props.selectedItems.map(x=>(parseInt(x))), visible: true, x: x, y: y, })
                    }
                    else{
                        setEnquiriesInUserProcessing(true)
                    }                    
                }
            }
        else{
            closeContextMenu();
            setContextMenuState({ keys:  [(parseInt(key))], visible: true, x: x, y: y })
        }

    }

     const onCheckboxClick = (itemClick: any) => {
        let sData = [...props.selectedItems];
        const newIndex = props.data.findIndex(x=> x.id.toString()==itemClick.currentTarget.value);
        setShiftSelectStart(newIndex);
        setShiftSelectEnd(newIndex);
         if(itemClick.currentTarget.checked){
             if (itemClick.shiftKey) {
                updateShiftSelection(newIndex);
             } else{
                 props.updateSelectedItems({multiSelect:true,selectedEnquiries:sData.concat((itemClick.currentTarget.value.toString()))});
             }
         }
         else{
             props.updateSelectedItems({multiSelect:props.multiSelect,selectedEnquiries:props.selectedItems.filter(x=> x!=(itemClick.currentTarget.value))});
         } 
     }

const keydownHandle = (event: React.KeyboardEvent<HTMLTableRowElement>) => {
    if (event == null)
        return;
    if (event.key != "ArrowUp" && event.key != "ArrowDown")
        return;
 
            let nextEnquiryIndex=0;
            let sStart = shiftSelectStart, sEnd = shiftSelectEnd;
            if (event.key == "ArrowUp") {
                if(sEnd <= sStart && sEnd ==0){
                    nextEnquiryIndex = 0
                }
                
                else {
                    nextEnquiryIndex = sEnd-1;
                }

            }
            else {
                if(sStart <= sEnd  && sEnd ==props.data.length-1){
                    nextEnquiryIndex = props.data.length-1;
                }
                else{
                    nextEnquiryIndex = sEnd+1;
                }

            }
 
    if(props.multiSelect==false)
        {
            if (props.selectedEnquiry != props.data[nextEnquiryIndex].id) {
                    const enqModule = new ActivateWizardModule();
                    enqModule.click = 0;
                    enqModule.showEnquiry(props.data[nextEnquiryIndex].id);
            }
        }
    else  {
        if(event.shiftKey==true){
            updateShiftSelection(nextEnquiryIndex);
        }
    }
}

    const getIdsByIndexes =(endIndex : number, startIndex : number):string[] =>{
      let ids:string[] = [];
      let indexes = Range(startIndex, endIndex)
        ids = indexes.map(x=> props.data[x].id.toString());
        return ids; 
    }

    const updateShiftSelection =(newEndIndex : number)=>{
        let currentSelectedEnquries:string[] = [];
        let previousSelectedEnquiries:string[] = []; 

        previousSelectedEnquiries = getIdsByIndexes(shiftSelectStart,shiftSelectEnd);
        currentSelectedEnquries = getIdsByIndexes(shiftSelectStart,newEndIndex);
 
        let sData = [...props.selectedItems];
        sData = sData.filter( ( x ) => !previousSelectedEnquiries.includes( x ) ); // remove the enquires that were selected previously
        sData =  [...new Set([...sData, ...currentSelectedEnquries])]; // merge the new selection with the previously selected items
        setShiftSelectEnd(newEndIndex);
        props.updateSelectedItems({multiSelect:true,selectedEnquiries:sData});
    }
    const updateCtrlSelection =(item : string)=>{
        let sData = [...props.selectedItems];
        if(sData.find(x=> x==item)){
            sData = sData.filter(i=> i!= item);
        }else{
            sData.push(item);
       }
       const newIndex = props.data.findIndex(x=> x.id.toString()==item);
        setShiftSelectStart(newIndex);
        setShiftSelectEnd(newIndex);
        props.updateSelectedItems({multiSelect:true,selectedEnquiries:sData});
    }
   
    const getExpiryColour = (e:iEnquiry):string => {
        let multiSelectClasses = "";
            if(props.multiSelect){
                if(props.selectedItems.find(x=> x==e.id.toString())!=undefined){
                    multiSelectClasses = "  multiSelect "
                }
                else if(e.id==props.selectedEnquiry){
                    multiSelectClasses= " multiSelectActiveEnquiry ";
                }
            }
        if (e.activeSlaCycle != null && !Statuses.IsCompleted(e.status)) {
            let reminderDate = e.activeSlaCycle.reminder? new Date( e.activeSlaCycle?.reminder) : "";
            let alarmDate = e.activeSlaCycle.alarm ? new Date(e.activeSlaCycle?.alarm) : "";
            let timeoutDate = e.activeSlaCycle.timeout ? new Date(e.activeSlaCycle?.timeout) : "";
            const currentDate = new Date();
            
            if (currentDate < reminderDate) {
                return  " expiry-green "  + multiSelectClasses;
            }
            else if (currentDate >= reminderDate && currentDate < alarmDate) {
                return  " expiry-orange "    + multiSelectClasses;
            }
            else if (currentDate >= alarmDate && currentDate < timeoutDate) {
                return  " expiry-red "   + multiSelectClasses;
            }
            else if (currentDate > reminderDate) {
                return  " expiry-purple "   + multiSelectClasses;
            }
            else{
                return "";
            }
        } else {
            return " expiry-none "+ multiSelectClasses;
        }
    }

     return (
        <>
        {AllColumns.length>0 ?  
<>
<div style={{float:"right",marginTop:"-40px"}}>
<EnquiryBurgerMenu
        key={"burger-menu-dropdown"} 
        enquiryIds={ props.multiSelect? props.selectedItems.map(x=>{return parseInt(x)}) : [props.selectedEnquiry]}
    ></EnquiryBurgerMenu>
   
   <div  style={{float:"right"}} className={props.multiSelect == false? "icon-button": " icon-button-selected "} title={props.multiSelect == false? "Select Multiple" : "De-select Multiple"}
   onClick={()=>{
            setShiftSelectStart(props.data.findIndex(x=> x.id==props.selectedEnquiry));
            setShiftSelectEnd(props.data.findIndex(x=> x.id==props.selectedEnquiry));
            props.updateSelectedItems({multiSelect:props.multiSelect== false? true: false,
    selectedEnquiries:props.multiSelect== false? props.selectedItems:  []});
            }
        }
   >
       <BsCheck2Square  style={{width:"14px",height:"14px"}}></BsCheck2Square> 
    </div>
    {props.multiSelect  ? <div style={{float:"right", fontSize:"9pt", margin:"1px 5px"}}>{props.selectedItems.length} selected of {props.data.length} </div> :""}
 </div>

        <DataTable data={props.data} theme="dataTable_default"
                    columns={AllColumns} uniqueIdentifiyer={(x)=>{return x.id.toString()}} 
                    onRowClick={(val:string, clickEVT) =>{
                        clickEVT.stopPropagation();
                        const newIndex = props.data.findIndex(x=> x.id.toString()==val)
                        if(props.multiSelect==false)
                            {
                                        if(clickEVT.ctrlKey==true){
                                            setShiftSelectStart(newIndex);
                                            setShiftSelectEnd(newIndex);
                                            props.updateSelectedItems({multiSelect:true,selectedEnquiries:
                                                [props.selectedEnquiry.toString(),val]
                                            });
                                            
                                        }
                                        else if(clickEVT.shiftKey==true){
                                            updateShiftSelection(newIndex);
                                        }else
                                        {
                                            setShiftSelectStart(newIndex);
                                            const enqM = new ActivateWizardModule();
                                            enqM.showEnquiry(parseInt(val));
                                        }
                            }
                                else
                            {
                                        switch(UserPreferences.general.enquiryList.multiSelectMode){
                                            case  "multi-preview"  :
                                                if(clickEVT.ctrlKey==true){
                                                    updateCtrlSelection(val);
                                                } 
                                                else if (clickEVT.shiftKey==true){
                                                    updateShiftSelection(newIndex);
                                                }
                                                else{
                                                    setShiftSelectEnd(newIndex);
                                                    const enqM = new ActivateWizardModule();
                                                    enqM.showEnquiry(parseInt(val));
                                                }
                                            break
                                            case  "multi-standard"  :
                                                if(clickEVT.ctrlKey==true){
                                                    updateCtrlSelection(val);
                                                } 
                                                else if (clickEVT.shiftKey==true){
                                                    updateShiftSelection(newIndex);
                                                }
                                            else{
                                                    const enqM = new ActivateWizardModule();
                                                    enqM.showEnquiry(parseInt(val));
                                                    props.updateSelectedItems({multiSelect:false,selectedEnquiries:[]});
                                                }
                                            break
                                        }
                            } 
                    }}  
                    onRowDblClick={(x: string) =>{
                            const enqM = new ActivateWizardModule();
                            enqM.ActivateWizard(parseInt(x), () => showModal({ type: "EnquiryLocked" }));
                    }}
                    selectedItem={props.selectedEnquiry.toString()}
                    selectedItems={props.selectedItems}
                    contextMenu={showDataTableContextMenu} keyPress={keydownHandle}
                    spacerRow={3}  
                    getCustomClass={(e)=>{
                        let classes ="";

                            classes =  getExpiryColour(e) ;
                            classes += (!Statuses.IsCompleted(e.status)) && e.hasNew ? " has-new" : "";
                      
                        return classes;
                    }}
                    lastItemInteracted={shiftSelectEnd}
                ></DataTable> </>: <></>
    }
    {(contextMenuState.visible) && (
                <EnquiryContextMenu
                    x={contextMenuState.x}
                    y={contextMenuState.y}
                    enquiryIds={contextMenuState.keys}
                    onHide={closeContextMenu}
                />
            )}
            {(enquiriesInUserProcessing)&&(
                <Confirm
                title='Warning'
                onConfirm={() => {
                    setEnquiriesInUserProcessing(false)
                }}
                
            >
                <p>One or many enquiries you selected is currently in User Processing. Please deselect it to perform your action.</p>

                </Confirm>
            )}
 </>      
    )
};

export default EnquiryGrid;

 