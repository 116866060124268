import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { userPreferences } from '../interfaces/userPrefence'
export interface iUser {
    userID: number,
    username: string,
    firstname: string,
    lastname: string,
    email: string,
    telephone: string,
    mobile: string,
    facsimile: string,
    updatedAt: string,
    jobTitle?: string | undefined,
    serviceCentre: string,
    mailboxes: Array<iMailbox>,

}
export interface iUserInformation extends iUser
{
    preferences: userPreferences
}
export interface iMailbox {
    id: number,
    name: string,
    emailAddress: string,
    telephone: string,
    facsimile: string,
    reference: string
}
const initialState: iUserInformation = {
    userID: 0,
    username: "",
    firstname: "",
    lastname: "",
    email: "",
    telephone: "",
    mobile: "",
    facsimile: "",
    updatedAt: new Date(0).toISOString(),
    serviceCentre: "",
    mailboxes: [],
    preferences: {general:{
        enquiryList:{
            multiSelectMode:"multi-preview"
        }
    }} 

}
export const UserInformation = createSlice({
    name: 'UserInformation',
    initialState,
    reducers: {
        setUser: (state, action) => {
            return {...action.payload}
        },
        setUserPreferences: (state, action) => {
           state.preferences =  action.payload
        },
    }
})

// Action creators are generated for each case reducer function
export const { setUser, setUserPreferences } = UserInformation.actions

export default UserInformation