import './communication.scss'
import { BsArrowDown, BsPaperclip, BsExclamationLg } from "react-icons/bs";
import {  dateTimeFormat222, } from '../enquiry/enquiry';
import { iGenericComm } from '../../../interfaces/iGenericComm';
import CommIcon from "./commIcon";
import React, {  useEffect } from 'react';
import { FormatSize } from '../../../Logic/StringFormatters';
import { iEmail } from '../../../interfaces/emailModel';


type CommsProp = {
    id?:number,
    type?:string,
    dateStamp?: string,
    email?:iEmail, 
    comm?: any,
    isCompletedEnquiry?: boolean,
    key?: string,
    interface?: string,
    onClick?: Function,
    activeCommId?: number,
    onKeyDown?: (activeCommid: number, keyevent: React.KeyboardEvent<HTMLTableRowElement>)=>void
    enquiryId:number
}
function Communication(c: CommsProp) {

    let activeEmail = false;
    const genericComm: iGenericComm = c.comm;
    if (c.activeCommId != undefined) {
        if (c.activeCommId == c.id) {
            activeEmail = true;
            let commContainer: HTMLElement = document.getElementById('commListHistory' + c.enquiryId)!;
            let commDiv: HTMLElement = document.getElementById("com_" + c.id)!;
            if(commDiv && commContainer){  
                if(c.activeCommId==c.id){
                    if(commDiv.offsetTop>100){
                        commContainer.scrollTop = commDiv.offsetTop-50;
                    }else{
                        commContainer.scrollTop = 0;
                    }
                }
            }
        }
    }
    else activeEmail = c.activeCommId == c.id
   
    const formattedSize = FormatSize(genericComm.size);
    const keydownHandler = (commId: number, event: React.KeyboardEvent<HTMLTableRowElement>) => {
        c.onKeyDown!(commId, event);    
    }
    const dateStamp = dateTimeFormat222(genericComm.date)
    return (
        <tr style={{ height: "24px", cursor: "pointer", paddingLeft: "4px", paddingRight: "10px", }} data-testid="communication--test-id" className={"communication" + (genericComm.unread && !c.isCompletedEnquiry ? " unread" : "") + (activeEmail ? " active" : "")} id={"com_" + c.id} key={"comm_" + c.id} tabIndex={0} onClick={() => { c.onClick!(c.id!) }} onKeyDown={(e1: React.KeyboardEvent<HTMLTableRowElement>): void => keydownHandler!(c.activeCommId!,e1)}>
            <td style={{ paddingLeft: "4px", width: "15px", textAlign: "center" }}>{

                genericComm.priority == 1 ? <BsExclamationLg className="exclamation" /> :
                    genericComm.priority == 2 ? <BsExclamationLg className="exclamation" /> :
                        genericComm.priority == 4 ? <BsArrowDown className="arrow" /> :
                            genericComm.priority == 5 ? <BsArrowDown className="arrow" /> :
                                ""
            }</td>
            <td style={{ width: "11px", textAlign: "center" }}>
                <CommIcon type={genericComm.type} unread={genericComm.unread} inBound={genericComm.incoming} />
            </td>
            <td style={{ width: "15px", textAlign: "center", paddingRight: "4px" }}>
                {genericComm.hasAttachments ? <BsPaperclip /> : <div className='comm-icon'></div>}
            </td>
            <td style={{ width: "12%" }} className='limit-text' title={`Date: ${dateStamp}`} data-testid='comm-dateStamp'>{dateStamp}</td>
            <td style={{ width: "20%" }} className='limit-text' title={`From: ${genericComm.from}`} data-testid='comm-senderName'>{genericComm.from}</td>
            <td style={{ width: "20%" }} data-tooltip={genericComm.to} className="comm-assignee limit-text" title={`To: ${genericComm.to}`} data-testid='comm-toAddress'>{genericComm.to}</td>
            <td style={{ width: "35%" }} className='limit-text' title={`Subject: ${genericComm.subject}`} data-testid='comm-subject'>{genericComm.subject}</td>
            <td style={{ width: "7%", paddingRight: "10px" }} className='limit-text' title={`Size: ${formattedSize}`} data-testid='comm-size'>{formattedSize}</td>
        </tr>
    );
}

export default Communication;

